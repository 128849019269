import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrganisationApiItemResponse } from '@fca-app/api/fca/arenas/interfaces/response/search-organisations-api.response';
import { AppMainConfigService } from '@fca-app/config/app-main-config.service';
import { FightEventModel } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import { ArenasService } from '@fca-app/services/arenas.service';
import { FightEventsService } from '@fca-app/services/fight-events.service';
import { EImageType } from '@fca-app/shared/enums/image-type.enum';
import { fileToBase64 } from '@fca-app/shared/helpers/file-to-base64.helper';
import { UploadedCropperImage } from '@fca-app/shared/interfaces/uploaded-cropper-image.interface';
import { untilDestroy, UntilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { FcaPreloadService } from '@fca-app/shared/services/fca-preload.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, take } from 'rxjs/operators';

export interface FormControlCheckboxItem {
    label: string;
    value: string;
    checked: boolean;
}

export interface SlotCardFighter {
    id?: string;
    firstName: string;
    lastName: string;
    image?: string;
}

@UntilDestroy()
@Component({
    selector: 'app-events-create',
    templateUrl: './create-event.component.html',
    styleUrls: ['./create-event.component.scss'],
})
export class CreateEventComponent {
    readonly maxSlots: number = 10;
    imageChangedEvent: Event;
    imageType = EImageType;
    isModalVisible$ = new BehaviorSubject<boolean>(false);
    onOrganisationSearch$ = new Subject<string>();
    previewPlaceholder: string;
    slots: number[] = [];
    form: FormGroup;
    foundOrganisations: OrganisationApiItemResponse[] = [];
    eventThumbnail?: File;
    eventHasBeenCreated: boolean = false;

    constructor(
        public readonly preloadService: FcaPreloadService,
        private readonly arenasService: ArenasService,
        private readonly fightEventsService: FightEventsService,
        private readonly appConfigService: AppMainConfigService,
        private readonly nzNotificationService: NzNotificationService
    ) {
        this.previewPlaceholder = this.previewPlaceholder || this.appConfigService.getConfig().previewImage;
        this.form = new FormGroup({
            priceInCoins: new FormControl(null, [Validators.min(1)]),
            discountPriceInCoins: new FormControl(null, [Validators.min(0)]),
            salePercentage: new FormControl(null, [Validators.min(0), Validators.max(100)]),
            isFreeForSubscribers: new FormControl(false, [Validators.required]),
            eventType: new FormControl('FIGHT', [Validators.required]),
            streamName: new FormControl(null, [Validators.required]),
            streamDescription: new FormControl(null, [Validators.required]),
            streamDate: new FormControl(new Date(), [Validators.required]),
            streamTime: new FormControl(new Date(), [Validators.required]),
            organisation: new FormControl(null, [Validators.required]),
            tags: new FormControl([], []),
            requirements: new FormControl([
                { label: 'Passport', value: 'PASSPORT', checked: true },
                { label: 'Cap', value: 'CAP', checked: true },
                { label: 'Jockstrap', value: 'JOCKSTRAP', checked: true },
                { label: 'Towel', value: 'TOWEL', checked: true },
            ]),
            slots: new FormGroup({}),
        });

        this.onOrganisationSearch$
            .pipe(
                filter(search => Boolean(search.length)),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap(search => this.arenasService.searchOrganisations(search)),
                untilDestroy(this)
            )
            .subscribe(organisations => {
                this.foundOrganisations = organisations;
            });
    }

    get slotsFromGroup(): FormGroup {
        return this.form.get('slots')! as FormGroup;
    }

    async onPhotoUpload(uploadedImage: UploadedCropperImage) {
        this.isModalVisible$.next(false);
        this.previewPlaceholder = String(await fileToBase64(uploadedImage.file));
        (this.imageChangedEvent.target as HTMLInputElement).value = '';
        this.eventThumbnail = uploadedImage.file;
    }

    get canAddNewPair(): boolean {
        return this.slots.length < this.maxSlots;
    }

    get matchmakingTitle(): string {
        return `Matchmaking (${this.maxSlots - this.slots.length})`;
    }

    onCreatePair() {
        if (this.canAddNewPair) {
            this.slots.push(this.slots.length);
        }
    }

    onCloseCropper() {
        this.isModalVisible$.next(false);
    }

    edit(e: Event) {
        this.imageChangedEvent = e;
        this.isModalVisible$.next(true);
    }

    onDeleteFightCard(num: number) {
        this.slots.splice(num - 1, 1);
    }

    onCreateEvent(): void {
        if (this.form.valid && !this.eventHasBeenCreated) {
            this.preloadService.preload(true);
            this.fightEventsService
                .create(this.form.value, this.eventThumbnail)
                .pipe(
                    take(1),
                    untilDestroy(this),
                    finalize(() => {
                        this.preloadService.preload(false);
                    })
                )
                .subscribe((model: FightEventModel) => {
                    this.eventHasBeenCreated = true;
                    this.nzNotificationService
                        .success(`Event "${model.name}" has been created`, '', { nzDuration: 1500 })
                        .onClick.pipe(take(1), untilDestroy(this))
                        .subscribe();
                });
        }
    }
}
