<nz-layout [formGroup]="form">
  <nz-sider nzWidth="300px">
    <nz-card nzTitle="Stream thumbnail" [nzActions]="[actionUpload]">
      <div>
        <img loading="lazy" class="image" nz-image [nzSrc]="previewPlaceholder" />
        <app-image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [imageType]="imageType.ARENA_PHOTO"
          (onUpload)="onPhotoUpload($event)"
          (onClose)="onCloseCropper()"
          [inputFile]="fileSelect"
          [isModalVisible]="!!(isModalVisible$ | async)"
        >
        </app-image-cropper>
        <input style="display: none" #fileSelect type="file" (change)="edit($event)" accept=".jpg, .jpeg, .png" />
      </div>
    </nz-card>
    <ng-template #actionUpload>
      <button nz-button nzType="dashed" (click)="fileSelect.click()">
        <i nz-icon nzType="upload"></i> Upload
      </button>
    </ng-template>
    <nz-card nzTitle="Broadcast type">
      <nz-select nzFlex="1" nzShowSearch nzAllowClear nzPlaceHolder="Event type" formControlName="eventType">
        <nz-option nzLabel="Fight" nzValue="FIGHT"></nz-option>
        <nz-option nzLabel="Podcast" nzValue="PODCAST"></nz-option>
        <nz-option nzLabel="Stream" nzValue="STREAM"></nz-option>
      </nz-select>
    </nz-card>
    <nz-card nzTitle="Organisation">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please select organisation">
          <input
            placeholder="Search..."
            #organisationsSearchInput
            nz-input
            (input)="onOrganisationSearch$.next(organisationsSearchInput.value)"
            formControlName="organisation"
            [nzAutocomplete]="auto"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-autocomplete #auto>
        <nz-auto-option
          *ngFor="let org of foundOrganisations"
          [nzValue]="org"
          nzLabel="{{ org.name }}"
        >
          {{ org.name }}
        </nz-auto-option>
      </nz-autocomplete>
    </nz-card>
    <nz-card nzTitle="Stream information">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input name">
          <input formControlName="streamName" nz-input placeholder="Name" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control nzErrorTip="Please input description">
          <textarea rows="4" nz-input placeholder="Description" formControlName="streamDescription"></textarea>
        </nz-form-control>
      </nz-form-item>

      <nz-row nzJustify="space-between">
        <nz-col nzFlex="1">
          <nz-date-picker formControlName="streamDate"></nz-date-picker>
        </nz-col>
        <nz-col nzFlex="1">
          <nz-time-picker nzFormat="HH:mm" formControlName="streamTime"></nz-time-picker>
        </nz-col>
      </nz-row>
    </nz-card>
    <nz-card nzTitle="Requirements">
      <nz-checkbox-group formControlName="requirements"></nz-checkbox-group>
    </nz-card>
  </nz-sider>
  <nz-layout>
    <nz-content>
      <nz-row>
        <nz-col nzSpan="12">
          <nz-card nzTitle="Price in coins">
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input correct price in coins">
                <input type="number" formControlName="priceInCoins" nz-input placeholder="Price" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input price with discount in coins">
                <input type="number" formControlName="discountPriceInCoins" nz-input placeholder="Discount price" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control nzErrorTip="Please input % of the discount">
                <input type="number" formControlName="salePercentage" nz-input placeholder="Discount in %" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-control>
                <nz-switch nzCheckedChildren="Free for subscribers" nzUnCheckedChildren="Paid for subscribers" formControlName="isFreeForSubscribers"></nz-switch>
              </nz-form-control>
            </nz-form-item>
          </nz-card>
        </nz-col>
        <nz-col nzSpan="12">
          <nz-card style="height: 50%" nzTitle="Add some tags">
            <nz-form-item>
              <nz-form-control nzErrorTip="">
                <nz-select nzMode="tags" nzPlaceHolder="Enter tag" formControlName="tags">
                  <nz-option *ngFor="let option of form.get('tags')!.value" [nzLabel]="option" [nzValue]="option"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </nz-card>
        </nz-col>
      </nz-row>

      <nz-card [nzTitle]=matchmakingTitle>
        <app-events-fight-slot
          *ngFor="let fight of slots; index as idx"
          [number]="idx + 1"
          [parentForm]="slotsFromGroup"
          (onDeleteCard)="onDeleteFightCard($event)"
        ></app-events-fight-slot>
        <button nz-button nzType="dashed" (click)="onCreatePair()" [disabled]="!canAddNewPair">
          <span>Add pair</span>
        </button>
      </nz-card>
    </nz-content>
    <nz-footer>
      <button nz-button nzType="primary" (click)="onCreateEvent()" [disabled]="!form.valid || eventHasBeenCreated" [nzLoading]="preloadService.isLoad$ | async">
        <span>{{eventHasBeenCreated ? 'Event has been created' : 'Create'}}</span>
      </button>
    </nz-footer>
  </nz-layout>
</nz-layout>
