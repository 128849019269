import { Injectable } from '@angular/core';
import {
    CreatePpvPriceData,
    FcaFightEventsApiService,
} from '@fca-app/api/fca/fight-events/fca-fight-events-api.service';
import {
    FormControlCheckboxItem,
    SlotCardFighter,
} from '@fca-app/dashboard/components/events/create/create-event.component';
import { FightEventFactory } from '@fca-app/models/users/arena/fight-event/fight-event.factory';
import { FightEventMapper } from '@fca-app/models/users/arena/fight-event/fight-event.mapper';
import { FightEventModel } from '@fca-app/models/users/arena/fight-event/fight-event.model';
import { ArenasService } from '@fca-app/services/arenas.service';
import { FakeUsersService } from '@fca-app/services/fake-users.service';
import { FightsService } from '@fca-app/services/fights.service';
import * as moment from 'moment-timezone';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mapTo, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class FightEventsService {
    constructor(
        private readonly fightEventsService: FcaFightEventsApiService,
        private readonly fightsService: FightsService,
        private readonly fakeUsersService: FakeUsersService,
        private readonly arenasService: ArenasService
    ) {}

    getById(id: string): Observable<FightEventModel> {
        return this.fightEventsService
            .getFightEvent(id)
            .pipe(map(raw => new FightEventFactory().getModelFromData(new FightEventMapper().mapData(raw))));
    }

    updatePpv(id: string, prices: CreatePpvPriceData[]): Observable<void> {
        return this.fightEventsService.updatePpv(id, prices);
    }

    create(
        data: {
            priceInCoins: number;
            discountPriceInCoins: number | null;
            salePercentage: number | null;
            isFreeForSubscribers: boolean | null;
            eventType: string;
            streamName: string;
            streamDescription: string;
            streamDate: string;
            streamTime: string;
            organisation: {
                id: number;
                name: string;
                tz: string;
            };
            tags: string[];
            requirements: FormControlCheckboxItem[];
            slots: Record<string, SlotCardFighter[]>;
        },
        thumbnail?: File
    ): Observable<FightEventModel> {
        const date = new Date(data.streamDate);
        const time = new Date(data.streamTime);
        const fromDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            time.getHours(),
            time.getMinutes(),
            time.getSeconds()
        );

        const params = {
            price: data.priceInCoins,
            discount: data.discountPriceInCoins,
            sale: data.salePercentage,
            freeForSubscribers: Boolean(data.isFreeForSubscribers),
            additional: {
                fightRequirements: (data.requirements as FormControlCheckboxItem[])
                    .filter(item => item.checked)
                    .map(item => item.value),
                type: data.eventType,
                description: data.streamDescription,
                providing: [],
            },
            organisationId: data.organisation.id,
            name: data.streamName,
            tags: data.tags,
            fromDate: moment.utc(moment(fromDate).tz(data.organisation.tz, true)).valueOf(),
            toDate: moment
                .utc(
                    moment(fromDate)
                        .add(5, 'hours')
                        .tz(data.organisation.tz, true)
                )
                .valueOf(),
            slots: Object.values(data.slots).reduce<{ left: SlotCardFighter; right: SlotCardFighter }[]>(
                (acc, item) => {
                    const fighters: SlotCardFighter[] = Object.values(item);
                    acc.push({
                        left: fighters[0],
                        right: fighters[1],
                    });

                    return acc;
                },
                []
            ),
        };

        return this.fightEventsService.create(params).pipe(
            map(res => {
                return new FightEventFactory().getModelFromData(new FightEventMapper().mapData(res));
            }),
            switchMap(model => {
                return params.slots.length > 0
                    ? forkJoin([
                          ...params.slots.map((slotToCreate, idx) => {
                              // если выбрали слева не существующего пользователя тогда его нужно создать
                              const obsToCreateRightFakeUserIfNeeds$ = !slotToCreate.right.id
                                  ? this.fakeUsersService
                                        .create(
                                            slotToCreate.right.firstName,
                                            slotToCreate.right.lastName,
                                            slotToCreate.right.image
                                        )
                                        .pipe(take(1))
                                  : of(null);
                              // если выбрали справа не существующего пользователя тогда его нужно создать
                              const obsToCreateLeftFakeUserIfNeeds$ = !slotToCreate.left.id
                                  ? this.fakeUsersService
                                        .create(
                                            slotToCreate.left.firstName,
                                            slotToCreate.left.lastName,
                                            slotToCreate.left.image
                                        )
                                        .pipe(take(1))
                                  : of(null);

                              return forkJoin([obsToCreateLeftFakeUserIfNeeds$, obsToCreateRightFakeUserIfNeeds$]).pipe(
                                  tap(([leftFakeUser, rightFakeUser]) => {
                                      // если какой-то из пользователей был создан, тогда берем его новый id
                                      if (leftFakeUser) {
                                          slotToCreate.left.id = leftFakeUser.id;
                                      }
                                      if (rightFakeUser) {
                                          slotToCreate.right.id = rightFakeUser.id;
                                      }
                                  }),
                                  switchMap(() => {
                                      return this.fightsService
                                          .createFight(
                                              model.fightSlots[idx].id,
                                              params.slots[idx].left.id && +params.slots[idx].left.id!
                                                  ? Number(params.slots[idx].left.id)
                                                  : undefined,
                                              params.slots[idx].right.id && +params.slots[idx].right.id!
                                                  ? Number(params.slots[idx].right.id)
                                                  : undefined,
                                              params.slots[idx].left.id && isNaN(+params.slots[idx].left.id!)
                                                  ? params.slots[idx].left.id
                                                  : undefined,
                                              params.slots[idx].right.id && isNaN(+params.slots[idx].right.id!)
                                                  ? params.slots[idx].right.id
                                                  : undefined,
                                              model.id
                                          )
                                          .pipe(take(1));
                                  })
                              );
                          }),
                      ]).pipe(take(1), mapTo(model))
                    : of(model);
            }),
            switchMap(model => {
                // грузим thumbnail если он есть
                return thumbnail
                    ? this.arenasService.uploadFightEventPhoto(model.id, 0, thumbnail).pipe(take(1), mapTo(model))
                    : of(model);
            })
        );
    }
}
