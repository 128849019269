import { AfterViewInit, Component } from '@angular/core';
import { FightTimeSlotModel } from '@fca-app/models/users/arena/fight-event/fight-time-slot/fight-time-slot.model';
import { FightsService } from '@fca-app/services/fights.service';
import { EFightResultType } from '@fca-app/shared/enums/fight-result-type.enum';
import { EFightWinner } from '@fca-app/shared/enums/fight-winner.enum';
import { UntilDestroy, untilDestroy } from '@fca-app/shared/operator/until-destroy.operator';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-arena-event-result',
    styleUrls: ['./results-modal.component.scss'],
    templateUrl: './results-modal.component.html',
})
export class ResultsModalComponent implements AfterViewInit {
    public readonly timeSlot: FightTimeSlotModel;
    public result: {
        fightId?: number;
        targetId?: number;
        initiatorId?: number;
        winner: EFightWinner | null;
        result: EFightResultType | null;
    } = {
        winner: null,
        result: null,
    };
    public readonly EFightWinner = EFightWinner;
    public resultTypes: EFightResultType[];

    constructor(
        private modal: NzModalRef,
        private readonly fightsService: FightsService,
        private readonly nzNotificationService: NzNotificationService
    ) {}

    public ngAfterViewInit(): void {
        this.result = {
            fightId: this.timeSlot.fight!.id,
            targetId: this.timeSlot.fight!.target?.id,
            initiatorId: this.timeSlot.fight!.initiator?.id,
            winner: null,
            result: null,
        };
    }

    public changeWinner(type: EFightWinner): void {
        this.result.winner = type;
        if ([EFightWinner.TARGET, EFightWinner.INITIATOR].includes(this.result.winner)) {
            this.resultTypes = [
                EFightResultType.KO,
                EFightResultType.TKO,
                EFightResultType.SUB,
                EFightResultType.DECISION,
            ];
        }
        if ([EFightWinner.BOTH].includes(this.result.winner)) {
            this.resultTypes = [EFightResultType.DECISION, EFightResultType.DRAW];
        }
        this.processUnblockingOkButton();
    }

    public changeResult(type: EFightResultType): void {
        this.result.result = type;
        this.processUnblockingOkButton();
    }

    private processUnblockingOkButton(): void {
        if (this.result.winner && this.result.result) {
            this.modal.updateConfig({ nzOkDisabled: false, nzOnOk: () => this.setWinner() });
        }
    }

    private setWinner(): void {
        this.modal.updateConfig({ nzOkLoading: true });
        this.fightsService
            .updateFightWinner(
                this.result!.fightId!,
                this.result!.winner!,
                this.result!.initiatorId!,
                this.result!.targetId!,
                this.result!.result!
            )
            .pipe(take(1), untilDestroy(this))
            .subscribe(() => {
                this.timeSlot!.fight!.winner = this.result!.winner!;
                this.nzNotificationService
                    .success('Success', '', { nzDuration: 1500 })
                    .onClick.pipe(take(1), untilDestroy(this))
                    .subscribe();
                this.modal.updateConfig({ nzOkLoading: false });
            });
    }
}
